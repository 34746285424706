/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

// Load application dependencies (jQuery and so on...)
import _ from 'lodash';
import $ from 'jquery';

// ================================
// START YOUR APP HERE
// ================================

let body = $('.content');
console.log(body.innerWidth());

let content = $('.content');
let main_holder = $('.main_holder');
let main_holder_allprojects = $('.main_holder_allprojects');
let nav = $('.nav');
let burguer = $('.burguer');
let cross = $('.cross');
let menu = $('.menu');
let idioms = $('.idioms');

function ocultar(){
    //if (top.location.pathname === 'index.ejs'){
    content.removeClass("content");
    content.toggleClass("contentclosed");
    //}
}
setTimeout(ocultar, 6000);

function entradamenu(){
    //if (top.location.pathname === 'index.ejs'){
    nav.addClass("enter");
    //}
}
setTimeout(entradamenu, 4500);

function entradacontenido(){
    //if (top.location.pathname === 'index.ejs'){
    main_holder.removeClass(".main_holder");
    main_holder.toggleClass("mainopened");
    //}
}
setTimeout(entradacontenido, 6000);

burguer.on("click", () => {
    burguer.toggleClass("closed");
    cross.toggleClass("opened");
    menu.toggleClass("opened");
    main_holder.toggleClass("opened");
    main_holder_allprojects.toggleClass("opened");
    idioms.toggleClass("closed");
});

window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  $(document).scroll(function() {
    if($(window).scrollTop() > 50){

        idioms.addClass("opened");

    }else if($(window).scrollTop() < 50){

        idioms.removeClass("opened");

    }
});




//$(function(){
//
  //  var CurrentScroll = 0;
  //  $(window).scroll(function(event){
  //
  //      var NextScroll = $(this).scrollTop();
  //
  //      if (NextScroll > CurrentScroll){
  //          idioms.addClass("opened");
  //      }
  //      else {
  //          idioms.removeClass("opened");
  //      }
  //
  //      CurrentScroll = NextScroll;  //Updates current scroll position
  //  });
  //});